import { Controller } from 'stimulus'

export default class extends Controller {
	isValidPostcode(postcode) {
		const portuguesePostcodePattern = /^\d{4}-\d{3}$/
		return portuguesePostcodePattern.test(postcode)
	}

	isValidMaxlength(value, maxLength) {
		return value.length <= maxLength
	}

	postalcode(event) {
		const element = event.currentTarget
		const cleanedValue = element.value.replace(/\D/g, '')

		if (cleanedValue.length > 4) {
			element.value = cleanedValue.slice(0, 4) + '-' + cleanedValue.slice(4, 7)
		} else {
			element.value = cleanedValue
		}

		if (!this.isValidPostcode(element.value)) {
			element.setCustomValidity('Insira um código postal válido')
		} else {
			element.setCustomValidity('')
		}
	}

	maxlength(event) {
		const element = event.currentTarget
		const value = element.value

		const maxLengthAttribute = element.getAttribute('data-maxlengthvalue')
		const maxLength = parseInt(maxLengthAttribute, 10)

		if (!this.isValidMaxlength(value, maxLength)) {
			element.setCustomValidity(`Insira até ${maxLength} caracteres`)
		} else {
			element.setCustomValidity('')
		}
	}
}
